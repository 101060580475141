<template>
  <div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="swiper_slider" v-for="(logo, i) in logos" :key="i">
        <a>
          <img :src="logo" alt="" />
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Autoplay]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    logos: {
      type: Array,
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 6,
        spaceBetween: 32,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          400: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 576px
          576: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          // when window width is >= 768px400
          768: {
            slidesPerView: 4,
            spaceBetween: 32,
          },
          992: {
            slidesPerView: 5,
            spaceBetween: 32,
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 32,
          },
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.swiper {
  width: 100%;
  position: relative;
  transition-timing-function: linear;
  &_slider {
    width: 176px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
