<template>
  <section>
    <header class="business">
      <div class="container">
        <div class="row">

          <div class="col-xl-7 col-ml-7 col-lg-6 col-md-6">
            <div class="bg_business">
              <img src="../assets/img/business.webp" alt="" />
            </div>
          </div>

          <div class="col-xl-5 col-ml-5 col-lg-6 col-md-6 bgVector">
            <div class="header_title">

              <h1>{{ localize("banner")["business"]["title"] }}</h1>

              <p>{{ localize("banner")["business"]["text"] }}</p>

              <a href="#form" class="btn_header">{{ localize("button")["sell"] }}</a>

            </div>
          </div>

        </div>
      </div>
    </header>

    <section id="scrollNav" class="benefits_working">
      <div class="container bgVector">

        <h2>{{ localize("benefits")["title"] }}</h2>

        <div class="row">
          <div
            class="col-xl-3 col-ml-3 col-lg-6 col-md-6 mb-4"
            v-for="(benefit, i) in benefits"
            :key="i"
          >
            <card-bus v-bind="benefit" styles="24px 0px 16px 0px" />
          </div>
        </div>
      </div>
    </section>

    <section class="carousel">
      <div class="container jcsb">
        <h2>{{ localize("carousel")["partners"]["title"] }}</h2>

        <router-link :to="{ name: 'partners' }" class="partners_link">
          {{ localize("carousel")["partners"]["all"] }}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.35355 5.64645C9.15829 5.45118 8.84171 5.45118 8.64645 5.64645C8.45118 5.84171 8.45118 6.15829 8.64645 6.35355L9.35355 5.64645ZM14.2929 11.2929L13.9393 11.6464V11.6464L14.2929 11.2929ZM14.2929 12.7071L13.9393 12.3536V12.3536L14.2929 12.7071ZM8.64645 17.6464C8.45118 17.8417 8.45118 18.1583 8.64645 18.3536C8.84171 18.5488 9.15829 18.5488 9.35355 18.3536L8.64645 17.6464ZM8.64645 6.35355L13.9393 11.6464L14.6464 10.9393L9.35355 5.64645L8.64645 6.35355ZM13.9393 12.3536L8.64645 17.6464L9.35355 18.3536L14.6464 13.0607L13.9393 12.3536ZM13.9393 11.6464C14.1346 11.8417 14.1346 12.1583 13.9393 12.3536L14.6464 13.0607C15.2322 12.4749 15.2322 11.5251 14.6464 10.9393L13.9393 11.6464Z"
              :fill="scssVars.main"
            />
          </svg>
        </router-link>
      </div>

      <div class="container">
        <carousel :logos="partnerLogos" />
      </div>
    </section>

    <section class="how_to_working">
      <div class="container">
        <h2>{{ localize("how-it-works")["title"] }}</h2>

        <div class="row">
          <div
            class="col-xl-4 col-ml-4 col-lg-4 col-md-6 mb-4"
            v-for="(information, i) in howItWorksInformationCards"
            :key="i"
          >
            <card-bus v-bind="information" />
          </div>
        </div>
      </div>
    </section>

    <section class="form" id="form">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-ml-6 col-lg-6 order-form">

            <h2 class="order-form-title">
              {{ localize("orders-form")["title"] }}
            </h2>

            <div ref="bitrix">
              <!-- todo: здесь есть битрикс скрипт которое генерирует форму   -->

              <button type="submit" class="P_button">
                {{ localize("button")["orders-send"] }}
              </button>
            </div>

          </div>

          <div class="col-xl-6 col-ml-6 col-lg-6 form_img text-right">
            <img src="../assets/img/order-form-bg.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
// Benefits icons
import shoppingcart from "@/assets/icons/chart-growth-positive.svg";
import laptop from "@/assets/icons/laptop.svg";
import cartIcon from "@/assets/icons/shopping-cart-add-2.svg";
import discount from "@/assets/icons/shield-check.svg";

// How it works information icons
import star from "@/assets/icons/chart-arrow-up.svg";
import lists from "@/assets/icons/lists.svg";
import globus from "@/assets/icons/globus.svg";

// Partner Logos
import MediaparkLogo from "@/assets/img/logo1.png";
import IdeaLogo from "@/assets/img/idea-logo.png";
import TStoreLogo from "@/assets/img/t-store-bottom-logo.png";
import TexnomartLogo from "@/assets/img/logo2.png";
import ArenamarkazLogo from "@/assets/img/arena-markaz-logo.svg";
import ElmakonLogo from "@/assets/img/elmakon-logo.png";

import carousel from "@/components/business/partners.vue";
import cardBus from "@/components/cardBus.vue";

import $ from "jquery";
import localize from "@/filters/localize.filter";
import { scssVars } from "@/utils/constants";

export default {
  name: "BusinessPage",
  data: () => ({
    scssVars,
    benefitIcons: [
      shoppingcart,
      laptop,
      lists,
      globus
    ],
    howItWorksInformationIcons: [
      cartIcon,
      discount,
      star
    ],
    benefits: [],
    howItWorksInformationCards: [],
    partnerLogos: [
      MediaparkLogo,
      IdeaLogo,
      TStoreLogo,
      TexnomartLogo,
      ArenamarkazLogo,
      ElmakonLogo
    ]
  }),
  components: {
    "card-bus": cardBus,
    carousel
  },
  methods: {
    localize,
    bitrixForm() {
      const scriptTag = document.createElement("script");

      scriptTag.setAttribute("data-b24-form", "click/30/ag61uw");

      scriptTag.setAttribute("data-skip-moving", "true");

      scriptTag.innerHTML = `
        (function(w,d,u) {
          var s=d.createElement('script');
          s.async=true;
          s.src=u+'?'+(Date.now()/180000|0);
          var h=d.getElementsByTagName('script')[0];
          h.parentNode.insertBefore(s,h);
        })(window,document,'https://cdn-ru.bitrix24.ru/b21501956/crm/form/loader_30.js')
      `;

      const bitrixContainer = this.$refs.bitrix;

      bitrixContainer.prepend(scriptTag);
    },
    createCards() {
      this.benefits = localize("benefits")["cards"].map((benefit, index) => ({
        ...benefit,
        icon: this.benefitIcons[index]
      }));

      this.howItWorksInformationCards = localize("how-it-works")["cards"].map((info, index) => ({
        ...info,
        icon: this.howItWorksInformationIcons[index]
      }));
    }
  },
  mounted() {
    this.createCards();

    this.bitrixForm();

    // Changing UI of navbar
    window.addEventListener("scroll", () => {
      const $scroll = $(window).scrollTop();
      const scrollNav = $("#scrollNav");

      if (scrollNav) {
        if ($scroll >= scrollNav.offset()?.top) {
          $(".pay_navbar").addClass("scrollAnim");
        }

        if ($scroll <= scrollNav.offset()?.top) {
          $(".pay_navbar").removeClass("scrollAnim");
        }
      }
    });

    // Animated scroll to bitrix form
    $(".btn_header").click(function(e) {
      e.preventDefault();

      const attr = $(this).attr("href");
      const form = $(attr).offset().top;

      $("html, body").animate({ scrollTop: form - 100 });
    });
  }
};
</script>
<style lang="scss" scoped>
@import '../assets/css/bussiness.scss';

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333% !important;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 50% !important;
  }
}

@media (min-width: 1200px) {
  .col-ml-4 {
    flex: 0 0 auto;
    width: 25% !important;
  }
  .col-ml-7 {
    flex: 0 0 auto;
    width: calc(100% / 12 * 7) !important;
  }
  .col-ml-5 {
    width: calc(100% / 12 * 5) !important;
  }
  .col-ml-3 {
    width: calc(100% / 12 * 3) !important;
  }
}

@media (min-width: 1400px) {
  .col-xl-4 {
    width: calc(100% / 12 * 4) !important;
  }
  .col-xl-7 {
    width: calc(100% / 12 * 7) !important;
  }
  .col-xl-5 {
    width: calc(100% / 12 * 5) !important;
  }
  .col-xl-3 {
    width: calc(100% / 12 * 3) !important;
  }
}

@media (min-width: 1600px) {
  .col-xxl-3 {
    width: calc(100% / 12 * 3) !important;
  }
}
</style>
